export class StatusValidation {
    id: number;
    type: StatusValidationType;
    status: StatusValidationStatus;
    rejectionReason: string;
    createdAt: string;
    updatedAt: string;

    constructor(obj?: Partial<StatusValidation>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export type StatusValidationType = 'user_pro';
export type StatusValidationStatus = 'pending' | 'validated' | 'rejected';
