import * as moment from 'moment';

export class Address {
    id: number;
    street: string;
    companyName: string;
    streetNumber: string;
    city: string;
    zip: string;
    country: string;
    mobilePhone: string;
    geolocation: { lat: string; lng: string };
    lat: string;
    lng: string;
    region: string;
    department: string;
    profileAddress: boolean;
    type: 'company' | 'personal';
    firstname: string;
    lastname: string;
    deliveryInstructions: string;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }

    toString(): string {
        const address: string[] = [];

        if (this.street) {
            address.push(this.street);
        }

        if (this.firstname || this.lastname) {
            address.push(`${this.firstname || ''} ${this.lastname || ''}`.trim());
        }

        if (this.companyName) {
            address.push(this.companyName);
        }

        if (this.streetNumber) {
            address.push(this.streetNumber);
        }

        if (this.zip) {
            address.push(this.zip);
        }

        if (this.city) {
            address.push(this.city);
        }

        if (this.country) {
            address.push(this.country);
        }

        return address.join(', ');
    }

    get streetFormatted() {
        return `${this.streetNumber || ''} ${this.street || ''}`.trim();
    }

    get cityFormatted() {
        return `${this.zip || ''} ${this.city || ''}`.trim();
    }

    get nameFormatted() {
        if (this.companyName) {
            return this.companyName;
        }

        return null;
    }
}

export class User {
    id: number;
    email: string;
    created_at: string;
    firstname: string;
    lastname: string;
    description: string;
    birthdate: string;
    company: number;
    type: 'personal' | 'pro' | 'team' = 'personal';
    phone: string;
    mainAddress: Address;
    picture: number; // media id
    companyPosition: string;
    rating: number;
    ratingCount: number;
    searchHeader: number; // media id
    country: string;
    iban: string;
    needProAccount: boolean;
    status: UserStatus;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.type = data?.type || 'personal';
            this.mainAddress = data?.mainAddress ? new Address(data?.mainAddress) : null;
        }
    }

    get fullname(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    getBirthdate(): string {
        if (!this.birthdate) {
            return null;
        }

        return moment(this.birthdate).format('DD/MM/YYYY');
    }

    get initials() {
        return `${this.firstname.charAt(0)}${this.lastname.charAt(0)}`.toUpperCase();
    }

    get pro(): boolean {
        return this.type === 'pro';
    }

    get team(): boolean {
        return this.type === 'team';
    }

    get personal(): boolean {
        return this.type === 'personal';
    }

    get needProValidation(): boolean {
        return this.status === 'none' && this.pro;
    }

    get hasRequestedProValidation(): boolean {
        return this.status === 'pending';
    }

    get hasDeclinedProValidation(): boolean {
        return this.status === 'rejected';
    }

    get hasValidatedProValidation(): boolean {
        return this.status === 'validated';
    }
}

export class AuthData {
    token: string | undefined;
    refreshToken: string | undefined;
    user: User | undefined;
}

export class CompanyAddresses {
    shipping: Address;
    return: Address;
    billing: Address;
}

export type UserStatus = 'none' | 'pending' | 'validated' | 'rejected';
