import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import Decimal from 'decimal.js';
import { Company } from './company';
import { OrderShipment } from './delivery';
import { Media } from './media';
import { Offer, PriceObject } from './offer';
import { OfferVariant } from './offer-definition';
import { PaymentMethod } from './payment';
import { Transaction } from './transaction';
import { Address, User } from './user';

export class Order {
    id: number;
    ref: string;
    totalWithoutTaxes: string;
    total: string;
    deliveryTotal: string;
    currency: string;
    status: string;
    createdAt: string;
    items: OrderItem[];
    deliveryAddress: Address;
    billingAddress: Address;
    seller: User;
    buyer: User;
    company: Company;
    customer: Customer;
    transactions: Transaction[];
    shipments: OrderShipment[];
    taxes: OrderTax[];
    invoice: Invoice;
    isWholesale: boolean;
    isGazette: boolean;
    isMarketplace: boolean;
    paymentMethod: PaymentMethod;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.items = (this.items || []).map((item) => new OrderItem(item));
            this.deliveryAddress = new Address(this.deliveryAddress);
            this.billingAddress = new Address(this.billingAddress);
            this.customer = new Customer(this.customer);
            this.transactions = (this.transactions || []).map((transaction) => new Transaction(transaction));
            this.shipments = (this.shipments || []).map((shipment) => new OrderShipment(shipment));
            this.taxes = (this.taxes || []).map((tax) => new OrderTax(tax));
            this.invoice = obj.invoice ? new Invoice(obj.invoice) : null;
        }
    }

    get totalPriceObject(): PriceObject {
        return { price: new Decimal(this.total), currency: this.currency };
    }

    get totalWithoutTaxesPriceObject(): PriceObject {
        return {
            price: new Decimal(this.totalWithoutTaxes),
            currency: this.currency,
        };
    }

    get deliveryTotalPriceObject(): PriceObject {
        return {
            price: new Decimal(this.deliveryTotal),
            currency: this.currency,
        };
    }

    get statusFormatted(): string {
        switch (this.status) {
            case 'pending':
                return _('Pending');
            case 'pending_wholesale_avrazya_approval':
            case 'pending_wholesale_seller_approval':
            case 'pending_wholesale_client_approval':
                return _('Pending');
            case 'pending_shipment':
                return _('Pending shipment');
            case 'pending_delivery':
                return _('Pending delivery');
            case 'pending_delivery_confirmation':
                return _('Pending delivery confirmation');
            case 'completed':
                return _('Completed');
            case 'pending_refund':
                return _('Pending refund');
            case 'refunded':
                return _('Refunded');
            case 'returned':
                return _('Returned');
            case 'pending_return':
                return _('Pending return');
            case 'cancelled':
                return _('Cancelled');
        }
    }

    get isShippable(): boolean {
        return this.status === 'pending_shipment';
    }

    get isPendingStatus(): boolean {
        return this.status?.startsWith('pending');
    }

    get statusColor(): string {
        if (this.status === 'completed' || this.status === 'refunded' || this.status === 'returned') {
            return 'text-success';
        }

        if (this.status === 'cancelled') {
            return 'text-danger';
        }

        return 'text-warning';
    }
}

export class OrderTax {
    id: number;
    name: string;
    rate: number;
    amount: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get amountPriceObject(): PriceObject {
        return { price: new Decimal(this.amount), currency: 'TRY' };
    }
}

export class OrderItem {
    id: number;
    name: string;
    quantity: number;
    price: string;
    variant: OfferVariant;
    offer: Offer;
    total: string;
    orderTax: OrderTax[];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.variant = new OfferVariant(this.variant);
            this.offer = new Offer(this.offer);
        }
    }

    get totalPriceObject(): PriceObject {
        return { price: new Decimal(this.total), currency: 'TRY' };
    }
}

export interface PostOrderLine {
    offer: number;
    quantity: number;
    variant?: number;
}

export interface PostOrder {
    country: string;
    customerEmail: string;
    customerFirstname: string;
    customerLastname: string;
    customerPhone: string;
    currency: string;
    deliveryAddress: number;
    billingAddress: number;
    paymentMethod: 'paytr' | 'bank_transfer';
    lines: PostOrderLine[];
    installments: number;
    bank: string;
}

export class Customer {
    firstname: string;
    lastname: string;
    email: string;
    mobilePhone: string;

    get fullname(): string {
        return `${this.firstname} ${this.lastname}`.trim();
    }

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class Invoice {
    id: number;
    number: string;
    media: Media;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.media = obj.media ? new Media(this.media) : null;
        }
    }
}
