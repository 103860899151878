import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '../services/translate.service';

export class AlertUtils {
    private static alertCtrl: AlertController;
    private static alert: HTMLIonAlertElement;
    private static translate: TranslateService;

    static init(alertCtrl: AlertController, translate: TranslateService) {
        this.alertCtrl = alertCtrl;
        this.translate = translate;
    }

    static async showSuccess(message: string) {
        if (!this.alertCtrl || this.alert) {
            return;
        }

        this.alert = await this.alertCtrl.create({
            message: this.translate.get(message),
            buttons: [
                {
                    text: this.translate.get(_('Continue')),
                    role: 'ok',
                },
            ],
        });

        await this.alert.present();
    }

    static async showError(message: string) {
        if (!this.alertCtrl || this.alert) {
            return;
        }

        this.alert = await this.alertCtrl.create({
            message: this.translate.get(message),
            buttons: [
                {
                    text: this.translate.get(_('Continue')),
                    role: 'ok',
                },
            ],
        });

        await this.alert.present();
    }

    static async askConfirmation(message: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (!this.alertCtrl || this.alert) {
                return;
            }

            this.alert = await this.alertCtrl.create({
                message: this.translate.get(message),
                buttons: [
                    {
                        text: this.translate.get(_('Cancel')),
                        role: 'cancel',
                        handler: () => resolve(false),
                    },
                    {
                        text: this.translate.get(_('Continue')),
                        role: 'ok',
                        handler: () => resolve(true),
                    },
                ],
            });

            await this.alert.present();
        });
    }
}
