import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '../services/translate.service';

export class LoadingUtils {
    private static loadingCtrl: LoadingController;
    private static loading: HTMLIonLoadingElement;
    private static translate: TranslateService;

    static init(loadingCtrl: LoadingController, translate: TranslateService) {
        this.loadingCtrl = loadingCtrl;
        this.translate = translate;
    }

    static async show(message: string = null) {
        if (!this.loadingCtrl || !this.translate) {
            return;
        }

        this.loading = await this.loadingCtrl.create({
            message: this.translate.get(message || _('Please wait...')),
        });
        await this.loading.present();
    }

    static hide() {
        if (!this.loading) {
            return;
        }
        this.loading.dismiss();
    }
}
